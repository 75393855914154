import React, { MouseEvent, useRef } from "react";
import descriptionDetailStyle from "@/styles/DescriptionDetail.module.scss";
import {
  FullDescriptionView,
  OwnerDescriptionView,
  ShortDescriptionView,
  ShortUserView,
  UserMeView,
} from "@/api/generated";
import AppLogo from "@/components/AppLogo";
import Avatar from "@/components/Avatar";
import useStorage from "@/hooks/useStorage";
import LikeButton from "@/components/LikeButton";
import postItemStyles from "@/styles/PostItem.module.scss";
import { useRouter } from "next/router";
import {
  KEY_CLOSE_FOOTER_ANIMATION,
  KEY_HIDE_FOOTER_ANIMATION_TIMES,
} from "@/lib/storageKeys";
import Icon from "@/components/Icon";
import BgImage from "@/components/BgImage";
import { getLogoImageSrcMono, isBlackFont } from "@/lib/layoutHelper";
import utilStyles from "@/styles/utils.module.scss";

export default function DescriptionCard({
  owner,
  author,
  description,
  descriptions,
  onPrevious,
  onNext,
  onMenu,
  onClose,
  style = {},
  showFooter = true,
  isPreview = false,
}: {
  owner: ShortUserView;
  author: ShortUserView;
  description:
    | OwnerDescriptionView
    | FullDescriptionView
    | ShortDescriptionView;
  descriptions: (
    | OwnerDescriptionView
    | FullDescriptionView
    | ShortDescriptionView
  )[];
  onPrevious: any;
  onNext: any;
  onMenu: any;
  onClose: any;
  style?: any;
  showFooter?: boolean;
  isPreview?: boolean;
}) {
  const { getLocalItem, getSessionItem } = useStorage();
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const hideFooterAnimation =
    getSessionItem(KEY_CLOSE_FOOTER_ANIMATION) === "true" ||
    parseInt(getLocalItem(KEY_HIDE_FOOTER_ANIMATION_TIMES) || "0") > 4;
  const showFooterAnimation = showFooter && !hideFooterAnimation;
  const linkToUser = (event: any, user: ShortUserView | UserMeView) => {
    event.stopPropagation();
    router.push("/" + (user.username || "404"));
  };
  const clickHandler = (event: MouseEvent) => {
    if (event.clientX / (ref.current?.offsetWidth || 400) < 0.3) {
      onPrevious();
      return;
    }

    onNext();
  };

  const coverImage = owner?.cover_image || "coverImage01";
  const coverImageSrc = getLogoImageSrcMono(coverImage);
  const isBlack = isBlackFont(coverImage);
  const textStyle = isBlack ? utilStyles.blackText : utilStyles.whiteText;

  return (
    <div
      className={descriptionDetailStyle.container}
      style={style}
      ref={ref}
      onClick={clickHandler}
    >
      <BgImage user={owner} />

      <div className={descriptionDetailStyle.innerContainer}>
        {isPreview ? (
          <>
            <div className={descriptionDetailStyle.previewHeader}>
              <AppLogo width={111} height={16} src={coverImageSrc} />

              <button onClick={onClose}>
                <Icon icon="cross_white" width={24} height={24} />
              </button>
              <span className={descriptionDetailStyle.previewHeaderText}>
                PREVIEW
              </span>
            </div>
          </>
        ) : (
          <div className={descriptionDetailStyle.header}>
            <div
              className={[
                descriptionDetailStyle.pagination,
                isBlack ? descriptionDetailStyle.paginationBlack : "",
              ].join(" ")}
            >
              {descriptions.length > 1 &&
                descriptions.map((page, index) => (
                  <div
                    key={index}
                    className={
                      page.id === description.id
                        ? descriptionDetailStyle.selected
                        : ""
                    }
                  />
                ))}
            </div>
            <button onClick={onClose}>
              <Icon
                icon={isBlack ? "cross_black" : "cross_white"}
                width={24}
                height={24}
              />
            </button>
          </div>
        )}

        <div className={descriptionDetailStyle.body}>
          <div className={descriptionDetailStyle.logo}>
            {!isPreview && (
              <AppLogo width={152} height={22} src={coverImageSrc} />
            )}
          </div>
          <div className={descriptionDetailStyle.owner}>
            <div
              className={descriptionDetailStyle.userItem}
              onClick={(event) => linkToUser(event, owner)}
            >
              <Avatar avatar={owner.avatar} width={27} height={27} />
              <div>
                <span
                  className={[descriptionDetailStyle.nameLabel, textStyle].join(
                    " "
                  )}
                >
                  {owner.full_name}
                </span>
                <span
                  className={[descriptionDetailStyle.label, textStyle].join(
                    " "
                  )}
                >
                  is
                </span>
              </div>
            </div>
          </div>

          <div className={[descriptionDetailStyle.text, textStyle].join(" ")}>
            {description.text}
          </div>

          <div className={descriptionDetailStyle.author}>
            <div
              className={[
                descriptionDetailStyle.userItem,
                descriptionDetailStyle.userItemFrom,
                postItemStyles.detail,
              ].join(" ")}
            >
              {description.approved ? (
                <LikeButton
                  description={description}
                  isDetail={true}
                  isBlack={isBlack}
                />
              ) : (
                <div />
              )}
              <div
                className={descriptionDetailStyle.from}
                onClick={(event) => linkToUser(event, author)}
              >
                <span
                  className={[descriptionDetailStyle.label, textStyle].join(
                    " "
                  )}
                >
                  from
                </span>
                <Avatar avatar={author.avatar} width={27} height={27} />
                <span
                  className={[descriptionDetailStyle.nameLabel, textStyle].join(
                    " "
                  )}
                >
                  {author.full_name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <footer>
          {showFooterAnimation && (
            <>
              <div className={descriptionDetailStyle.object} />
              <div className={descriptionDetailStyle.navigation}>
                <span>Swipe</span>
              </div>
            </>
          )}
        </footer>
      </div>
    </div>
  );
}
