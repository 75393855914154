import React, { TouchEvent, useContext, useEffect, useState } from "react";
import {
  DescriptionDispatchContext,
  DescriptionStateContext,
} from "@/contexts/DescriptionContext";
import Api from "@/api";
import descriptionDetailStyle from "@/styles/DescriptionDetail.module.scss";
import Loading from "@/components/Loading";
import DescriptionCard from "@/components/DescriptionCard";
import { useRouter } from "next/router";
import {
  FullDescriptionView,
  OwnerDescriptionView,
  ShortDescriptionView,
} from "@/api/generated";
import useStorage from "@/hooks/useStorage";
import {
  KEY_CLOSE_FOOTER_ANIMATION,
  KEY_HIDE_FOOTER_ANIMATION_TIMES,
} from "@/lib/storageKeys";
import { useAuthHeaders } from "@/lib/authHeader";
import { useBaseActions } from "@/hooks/useBaseActions";

export default function DescriptionDetail() {
  const { authHeader } = useAuthHeaders();
  const state = useContext(DescriptionStateContext);
  const dispatch = useContext(DescriptionDispatchContext);
  const { showModal } = useBaseActions();
  const router = useRouter();
  const { getLocalItem, setLocalItem, setSessionItem } = useStorage();

  const [isDragging, setIsDragging] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [startPositionX, setStartPositionX] = useState(0);
  const [ownerDescription, setOwnerDescription] = useState<
    OwnerDescriptionView | FullDescriptionView | ShortDescriptionView
  >();
  const [ownerDescriptions, setOwnerDescriptions] =
    useState<
      (OwnerDescriptionView | FullDescriptionView | ShortDescriptionView)[]
    >();
  const [authorDescription, setAuthorDescription] = useState<
    OwnerDescriptionView | FullDescriptionView | ShortDescriptionView
  >();
  const [authorDescriptions, setAuthorDescriptions] =
    useState<
      (OwnerDescriptionView | FullDescriptionView | ShortDescriptionView)[]
    >();
  const [startPageX, setStartPageX] = useState(0);
  const [pageX, setPageX] = useState(0);
  const [currentStyle, setCurrentStyle] = useState({});
  const [nextStyle, setNextStyle] = useState({});

  useEffect(() => {
    const rotateY = -pageX * 60;
    const translateX = -pageX * window.innerWidth;
    const opacity = 1 - Math.abs(pageX / 2);
    setCurrentStyle({
      transformOrigin: pageX < 0 ? "left" : "right",
      transform: `translate3d(${translateX}px,0,0) rotateY(${rotateY}deg)`,
      opacity: opacity,
      transition: isAnimating ? "transition: 0.2s ease-out" : "none",
    });

    const nextRotateY = (1 - pageX) * 60;
    const nextTranslateX = -pageX * window.innerWidth;
    const nextOpacity = 0.5 + Math.abs(pageX / 2);
    setNextStyle({
      marginLeft: "100%",
      transformOrigin: pageX < 1 ? "left" : "right",
      transform: `translate3d(${nextTranslateX}px,0,0) rotateY(${nextRotateY}deg)`,
      opacity: nextOpacity,
      transition: isAnimating ? "transition: 0.2s ease-out" : "none",
    });
  }, [pageX, isAnimating]);

  useEffect(() => {
    setOwnerDescription(
      state.descriptions.find(
        (description) => description.id === state.ownerDescriptionId
      )
    );
  }, [state.ownerDescriptionId, state.descriptions]);

  useEffect(() => {
    setOwnerDescriptions(
      state.descriptions.filter((description) =>
        state.ownerDescriptionIds.includes(description?.id || "")
      )
    );
  }, [state.ownerDescriptionIds, state.descriptions]);

  useEffect(() => {
    setAuthorDescription(
      state.descriptions.find(
        (description) => description.id === state.authorDescriptionId
      )
    );
  }, [state.authorDescriptionId, state.descriptions]);

  useEffect(() => {
    setAuthorDescriptions(
      state.descriptions.filter((description) =>
        state.authorDescriptionIds.includes(description?.id || "")
      )
    );
  }, [state.authorDescriptionIds, state.descriptions]);

  const onTouchStart = (e: TouchEvent) => {
    setStartPositionX(e.touches[0].clientX);
    setStartPageX(pageX);
  };

  const onTouchMove = (e: TouchEvent) => {
    const positionX = e.touches[0].clientX - startPositionX;
    if (Math.abs(positionX) > 2) {
      // e.preventDefault();
    }
    setPageX(-positionX / window.innerWidth + startPageX);
    setIsDragging(true);
  };

  const onTouchend = (e: TouchEvent) => {
    if (!isDragging) return;
    if (pageX < -0.2) {
      onClose();
    }
    if (1.3 < pageX) {
      router.push("/" + ownerDescription?.author?.username || "/404");
      onClose();
      return;
    }
    pageX > 0.5 ? toPage(1, true) : toPage(0, true);
    setIsDragging(false);
  };

  const toPage = (page: number, animation: boolean) => {
    setPageX(page);
    if (animation) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 200);
    }
    if (page === 1) {
      setLocalItem(
        KEY_HIDE_FOOTER_ANIMATION_TIMES,
        (
          parseInt(getLocalItem(KEY_HIDE_FOOTER_ANIMATION_TIMES) || "0") + 1
        ).toString()
      );
      setSessionItem(KEY_CLOSE_FOOTER_ANIMATION, "true");
    }
  };

  const onClose = () => {
    dispatch({
      type: "CLOSE_PREVIEW",
    });
    setIsAnimating(false);
    setStartPositionX(0);
    setStartPageX(0);
    setPageX(0);
    setCurrentStyle({});
    setNextStyle({ marginLeft: "-100%" });
    setIsDragging(false);
  };

  const menuHandler = (e: any) => {
    e.stopPropagation();

    showModal("MENU_MODAL");
  };

  const previousOwnerHandler = () => {
    nextDescriptionHandler(
      ownerDescription || null,
      ownerDescriptions || null,
      true,
      true
    );
  };

  const nextOwnerHandler = () => {
    nextDescriptionHandler(
      ownerDescription || null,
      ownerDescriptions || null,
      true,
      false
    );
  };

  const previousAuthorHandler = () => {
    nextDescriptionHandler(
      authorDescription || null,
      authorDescriptions || null,
      false,
      true
    );
  };

  const nextAuthorHandler = () => {
    nextDescriptionHandler(
      authorDescription || null,
      authorDescriptions || null,
      false,
      false
    );
  };

  const nextDescriptionHandler = (
    description:
      | OwnerDescriptionView
      | FullDescriptionView
      | ShortDescriptionView
      | null,
    descriptions:
      | (OwnerDescriptionView | FullDescriptionView | ShortDescriptionView)[]
      | null,
    isOwner: boolean,
    isReverse: boolean
  ) => {
    if (!description?.id || !descriptions?.length) {
      return;
    }

    const nextDescription = getNextDescription(
      description,
      descriptions,
      isReverse
    );

    if (!nextDescription?.id) {
      return;
    }

    if (description.id === nextDescription?.id || "") {
      return;
    }

    dispatch({
      type: isOwner ? "UPDATE_OWNER_DESCRIPTION" : "UPDATE_AUTHOR_DESCRIPTION",
      payload: {
        descriptionId: nextDescription?.id || "",
      },
    });

    if (!authHeader) return;

    Api.MyDescriptions.getApiDescriptionsMeRepliedDescription(
      nextDescription.id || "",
      authHeader
    ).then((res) => {
      dispatch({
        type: "ADD_DESCRIPTIONS",
        payload: {
          descriptions: [res.data],
        },
      });
      dispatch({
        type: isOwner
          ? "UPDATE_AUTHOR_DESCRIPTION"
          : "UPDATE_OWNER_DESCRIPTION",
        payload: {
          descriptionId: res.data?.id || "",
        },
      });
    });

    Api.MyDescriptions.getApiDescriptionsMeListByUsername(
      nextDescription.author?.username || "",
      0,
      50,
      "updatedAt|desc",
      authHeader
    ).then((res) => {
      dispatch({
        type: "ADD_DESCRIPTIONS",
        payload: {
          descriptions: res.data.data || [],
        },
      });
      dispatch({
        type: isOwner
          ? "UPDATE_AUTHOR_DESCRIPTIONS"
          : "UPDATE_OWNER_DESCRIPTIONS",
        payload: {
          descriptionIds: (res.data?.data || []).map(
            (description) => description?.id || ""
          ),
        },
      });
    });
  };

  if (!state.showPreview) return null;

  if (
    !ownerDescription ||
    !ownerDescription.owner ||
    !ownerDescription.author
  ) {
    return <Loading />;
  }

  const getNextDescription = (
    description:
      | OwnerDescriptionView
      | FullDescriptionView
      | ShortDescriptionView,
    descriptions:
      | (OwnerDescriptionView | FullDescriptionView | ShortDescriptionView)[],
    isReverse: boolean
  ) => {
    const index = descriptions.findIndex(
      (findDescription) => findDescription.id === description?.id
    );

    if (index < 0) return null;

    if (isReverse) {
      const nextIndex = index === 0 ? descriptions.length - 1 : index - 1;

      return descriptions[nextIndex];
    }

    const nextIndex = index + 1 >= descriptions.length ? 0 : index + 1;

    return descriptions[nextIndex];
  };

  return (
    <div
      className={descriptionDetailStyle.container}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchend}
    >
      <DescriptionCard
        description={ownerDescription}
        descriptions={ownerDescriptions || []}
        owner={ownerDescription.owner}
        author={ownerDescription.author}
        onPrevious={previousOwnerHandler}
        onNext={nextOwnerHandler}
        onMenu={menuHandler}
        onClose={onClose}
        style={currentStyle}
      />
      {authorDescription && (
        <DescriptionCard
          description={authorDescription}
          descriptions={authorDescriptions || []}
          owner={ownerDescription.author}
          author={ownerDescription.owner}
          onPrevious={previousAuthorHandler}
          onNext={nextAuthorHandler}
          onMenu={menuHandler}
          onClose={onClose}
          style={nextStyle}
          showFooter={false}
        />
      )}
    </div>
  );
}
